import React from 'react';

import Layout from '../components/Layout';
import pic4 from '../assets/images/DSC_0320_s.jpg';
import pic5 from '../assets/images/DSC_0324_s.jpg';
import pic6 from '../assets/images/DSC_0335.jpg';
import pic7 from '../assets/images/DSC_0368.jpg';
import pic8 from '../assets/images/DSC_0330.jpg';
import pic9 from '../assets/images/DSC_0343.jpg';
import pic3 from '../assets/images/DSC_0381.jpg';
import pic10 from '../assets/images/maxine_new1.jpg';
import pic11 from '../assets/images/maxine_new2.jpg';

const IndexPage = () => (
  <Layout fullMenu>
    <section id="wrapper">
      <header>
        <div className="inner">
          <h2>About Maxine</h2>
        </div>
      </header>

      <div className="wrapper">
        <section className="aa">
          <article>
            <div
              className="image"
              style={{ display: 'flex', justifyContent: 'center' }}
            >
              <img
                src={pic3}
                style={{ width: '256px', height: '384px' }}
                alt=""
              />
            </div>
          </article>
        </section>

        <div className="inner">
          <p>
            Maxine started learning the cello at the age of six and the piano at
            the age of five. Cello quickly became her main instrument and she
            won a County Scholarship at the age of ten and received an ABRSM
            Gold Award for the highest marks in the country for her Grade 8 at
            the age of seventeen. As a teenager Maxine learnt with Florence
            Hooton MBE and she went on to study at the Royal College of Music in
            London, learning with Michael Evans and Joan Dickson. At the RCM
            Maxine studied cello as her first instrument and piano as her
            second.
          </p>

          <section className="features">
            <article>
              <div className="image">
                <img src={pic4} alt="" />
              </div>
            </article>
            <article>
              <div className="image">
                <img src={pic5} alt="" />
              </div>
            </article>
          </section>
          <p>
            During her freelance career, Maxine has played for the Queen and has
            also played alongside Mstislav Rostropovich, Jose Carreras, Hayley
            Westenra and ‘All Angels’. She has also toured with Castleward Opera
            in Northern Ireland, played with the Ulster Orchestra and in the
            West End musical “CATS” in Zurich. She now works as a freelance
            player and teacher in and around the Midlands where she manages, and
            plays in, her string quartet, “4tissimo”, whilst being a full-time
            mum to her two boys.
          </p>

          <section className="features">
            <article>
              <div className="image">
                <img src={pic6} alt="" />
              </div>
            </article>
            <article>
              <div className="image">
                <img src={pic8} alt="" />
              </div>
            </article>
          </section>
          <section className="features">
            <article>
              <div className="image">
                <img src={pic7} alt="" />
              </div>
            </article>
            <article>
              <div className="image">
                <img src={pic9} alt="" />
              </div>
            </article>
          </section>
          <section className="features">
            <article>
              <div className="image">
                <img src={pic10} alt="" />
              </div>
            </article>
            <article>
              <div className="image">
                <img src={pic11} alt="" />
              </div>
            </article>
          </section>
        </div>
      </div>
    </section>
  </Layout>
);

export default IndexPage;
